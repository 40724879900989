body {
    overflow-y: scroll;
}
h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    // letter-spacing: 2px;
}

.fs-13 {
    font-size: 13px;
}
.fs-16{
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}

.container-fluid-2200 {
    margin-right: auto;
    margin-left: auto;
    padding-left: 50px;
    padding-right: 50px;
    max-width: 2300px;
}
.container-fluid-1200 {
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
}

@media (min-width: 576px) {
    .container-sm,
    .container {
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px;
        // max-width: 100%;
    }
}

// @media (min-width: 1400px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 1340px;
//     }
// }

// @media (min-width: 1600px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 1540px;
//     }
// }

// @media (min-width: 1900px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 1840px;
//     }
// }
.w-min-65 {
    min-width: 65px;
}
.w-min-100 {
    min-width: 100px;
}
.w-min-120 {
    min-width: 120px;
}
.w-min-140 {
    min-width: 140px;
}
.w-min-200 {
    min-width: 200px;
}
.w-max-360 {
    max-width: 360px;
}
.signin-section {
    max-width: 360px;
    margin: 0 auto;
}

.border-dashed{
    border-style: dashed;
}

ol,
ul {
    margin: 0;
    padding: 0;
}
li {
    list-style: none;
}

[role="button"] {
    &:focus {
        outline: none;
    }
}

.y-ellipsis {
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    &.clamp-2 {
        -webkit-line-clamp: 2;
    }
}


.btn-white{

    color: color-yiq($white);
    background-color: $white;
    border-color: $white;

    &:hover,
    &.hover{
        color: color-yiq($white);
        background-color: darken($white, 7.5%);
        border-color: darken($white, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($white, .5);
    }

    &:active,
    &.active{
        color: color-yiq($white);
        background-color: darken($white, 10%);
        border-color: darken($white, 12.5%);
    }
}


/*
aspect ratio  |  multiply width by
-----------------------------------
     1:1      |         1
     1:3      |         3
     4:3      |        0.75
    16:9      |       0.5625
*/
.aspect-ratio {
    width: 100%;
    position: relative;
    // 1:1
    padding-top: 100%;

    &.pic {
        height: 0;
        overflow: hidden;
        border-radius: 0.25rem;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.aspect-ratio-1 {
    // 16:9
    padding-top: 56.25%;
}
.aspect-ratio-2 {
    // 4:3
    padding-top: 75%;
}
.aspect-ratio-3 {
    // 3:2
    padding-top: 66.66%;
}
.aspect-ratio-4 {
    // 8:5
    padding-top: 62.5%;
}

.checkout-wizard {
    width: 410px;
    margin: 0 auto;
    padding-bottom: 24px;
}
@media (max-width: 575px) {
    .checkout-wizard {
        width: auto;
        padding-bottom: 0;
    }
}
.checkout-wizard .step {
    display: flex;
    position: relative;
    color: #b2b2b4;
}
.checkout-wizard .step:not(:last-child) {
    flex-grow: 1;
    padding-right: 8px;
}
.checkout-wizard .step:not(:last-child):after {
    content: "";
    border-bottom: 2px dotted #b2b2b4;
    height: 1px;
    flex-grow: 1;
    min-width: 32px;
    margin-left: 8px;
    margin-top: 16px;
}
@media (max-width: 575px) {
    .checkout-wizard .step:not(:last-child):after {
        margin-top: 10px;
    }
}
.checkout-wizard .step .step-label {
    position: relative;
}
@media (max-width: 575px) {
    .checkout-wizard .step .step-label {
        display: flex;
        align-items: center;
        font-size: 12px;
    }
}
@media (min-width: 575px) {
    .checkout-wizard .step .step-label .text-action {
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        width: 120px;
        text-align: center;
    }
}
.checkout-wizard .step.success,
.checkout-wizard .step.success a {
    color: #000106;
}
.checkout-wizard .step.success .number-step,
.checkout-wizard .step.success a .number-step {
    border: 2px solid $primary;
    color: $primary;
    background: #fff;
    line-height: 26px;
}
@media (max-width: 575px) {
    .checkout-wizard .step.success .number-step,
    .checkout-wizard .step.success a .number-step {
        line-height: 18px;
    }
}
.checkout-wizard .step.success .number-step i,
.checkout-wizard .step.success a .number-step i {
    margin: 0 auto;
}
.checkout-wizard .step.success:after,
.checkout-wizard .step.success a:after {
    border: 1px solid $primary;
}
.checkout-wizard .step.active {
    color: #000106;
}
.checkout-wizard .step.active .number-step {
    background: $primary;
    color: #fff;
}
.checkout-wizard .number-step {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    color: #b2b2b4;
    line-height: 30px;
    margin: 0 auto 4px;
}
@media (max-width: 575px) {
    .checkout-wizard .number-step {
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-bottom: 0;
        margin-right: 4px;
    }
}
.checkout-wizard ul {
    justify-content: space-between;
}
.checkout-wizard ul li {
    list-style: none;
}

.btn-error {
    -webkit-animation: shake 0.5s;
    animation: shake 0.5s;
}
@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

.row-sm {
    margin-right: -8px;
    margin-left: -8px;
}
.row-sm > {
    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.loader-wrap,
.btn-submit {
    position: relative;
    overflow: hidden;

    &.loading{
        .loader{
            opacity: 1;
        }
    }

    .loader{
        position: absolute;
        height: auto;
        width: 100%;
        z-index: 2;
        left: 0;
        top: 50%;
        text-align: center;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-50%);
    }

    .loader > div {
        display: inline-block;
        float: none;
        vertical-align: baseline;
        width: 8px;
        height: 8px;
        padding: 0;
        border: none;
        margin: 2px;
        opacity: 0.4;
        border-radius: 7px;
        background-color: hsla(0, 0%, 100%, 0.9);
        transition: background-color 0.2s;
        animation: btn-submit-loading-animate 1s infinite;
    }
    .loader > div:nth-child(3n + 2) {
        animation-delay: 0.15s;
    }
    .loader > div:nth-child(3n + 3) {
        animation-delay: 0.3s;
    }
}
.loader-wrap{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 12px;

    .loader{
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader > div {

        width: 8px;
        height: 8px;
        margin: 5px;
    }
}

@keyframes btn-submit-loading-animate {
    0% {
        opacity: 0.4;
    }
    20% {
        opacity: 0.4;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0.4;
    }
}

.form-overlay{
    background: rgba(0,0,0,.5);position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 500;

    .loader{
        opacity: 1
    }

    .loader > div {

        width: 12px;
        height: 12px;
        margin: 5px;
    }
}
