@font-face {
    font-family: 'sukhumvit-tadmai';
    src: url('../fonts/SukhumvitTadmai-Bold.woff2') format('woff2'),
        url('../fonts/SukhumvitTadmai-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'sukhumvit-tadmai';
    src: url('../fonts/SukhumvitTadmai-SemiBold.woff2') format('woff2'),
        url('../fonts/SukhumvitTadmai-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'sukhumvit-tadmai';
    src: url('../fonts/SukhumvitTadmai-Thin.woff2') format('woff2'),
        url('../fonts/SukhumvitTadmai-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'sukhumvit-tadmai';
    src: url('../fonts/SukhumvitTadmai-Text.woff2') format('woff2'),
        url('../fonts/SukhumvitTadmai-Text.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sukhumvit-tadmai';
    src: url('../fonts/SukhumvitTadmai-Light.woff2') format('woff2'),
        url('../fonts/SukhumvitTadmai-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'sukhumvit-tadmai';
    src: url('../fonts/SukhumvitTadmai-Medium.woff2') format('woff2'),
        url('../fonts/SukhumvitTadmai-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
